<template>
    <div class="text-center" v-if="!is_data_ready">
        <div class="spinner-border" role="status">
                <span class="sr-only">Loading...</span>
            </div>
    </div>
    <div class="tab-pane fade show active text-center" v-else-if="innings.length == 0">
        <p>{{ $t("No overs data found") }}.</p>
    </div>
    <div v-else>
        <div class="innings_tabs cr-submain-tab">
            <ul>
                <li v-for="(inning,index) in innings" :key="inning.order">
                    <a :class="activeInningIndex == index ? 'selected':''" style="cursor: pointer" @click=" activeInningIndex = index; getWicket(inning)" href="javascript:;">
                        <span>{{ inning.caption }}
                        </span>
                    </a>
                </li>
            </ul>
            <div class="clearfix"></div>
        </div>
        <div class="cr-global-table fall-off-wicket">
            <div class="table-responsive">
                <table>
                    <thead>
                    <tr>
                        <th class="text-left"> {{ $t("cric.Fall of Wickets") }}</th>
                        <th class="text-left">{{ $t("cric.Player") }} </th>
                        <th> {{ $t("cric.Score") }}</th>
                        <th> {{ $t("cric.Overs") }}</th>
                        <th class="text-left">{{ $t("cric.Bowler") }} </th>
                        <th class="text-left"> {{ $t("cric.Out") }}</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="wicket in matchWickets.cricMatch.cricFallofwickets">
                        <td class="text-left"><b>{{wicket.number}}</b></td>
                        <td class="text-left"> {{wicket.player.name}}</td>
                        <td><b>{{wicket.score}}</b></td>
                        <td><b>{{wicket.ball}}</b></td>
                        <td class="text-left"> {{wicket.bowler.name}}</td>
                        <td class="text-left"> {{ wicket.wicket }}</td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "wicket",
        data() {
            return {
                matchWickets: null,
                activeInningIndex: 0,    //active tab inning
                matchCurrentInning: 0,
                innings: '',
                inningsInterval: null
            }
        },
        metaInfo() {
            let ss = this.$store.state.siteSetting;
            var replacements = {
                '%HOME_TEAM%': this.$store.getters.getMatchDetailResponse.team1.name,
                '%AWAY_TEAM%': this.$store.getters.getMatchDetailResponse.team2.name,
            };

            let title = this.$getSafe(() => this.$replacePlaceHolders(ss.cricket_match_wicket_meta_title,replacements));
            let description = this.$getSafe(() => this.$replacePlaceHolders(ss.cricket_match_wicket_meta_description,replacements));
            let keywords = this.$getSafe(() => ss.cricket_match_wicket_meta_keywords);

            return {
                title: title,
                description: description,
                meta: this.$getMetaTags(title, description, keywords),
                link:[
                    {rel: 'canonical', href: this.$toFullPath(this.$route.fullPath)}],
            };
        },
        mounted() {
            this.main();
            var _this = this;
            this.$root.$on('CricMatchUpdated', data => {
                if (data.match_id == _this.$route.params.id && _this.activeInningIndex == _this.matchCurrentInning) {
                    _this.getWicket(_this.innings[_this.innings.length - 1])
                }
            });
            this.inningsInterval = setInterval(() => {
                this.innings = this.$store.getters.getMatchDetailResponse.innings;
            }, 60000);
        },
        beforeDestroy() {
            this.$root.$off('CricMatchUpdated')
            clearInterval(this.inningsInterval);
        },
        serverPrefetch() {
            return this.main();
        },
        methods: {
            getWicket(inning) {
                this.is_data_ready = false;
                return this.$axios
                    .get(this.$MATCH_WICKET_API.replace(':match', this.$route.params.slug), {
                        params: {
                            inning: inning.order,
                            team: inning.team_id,
                        }
                    })
                    .then(response => {
                        if (response.data) {
                            this.matchWickets = response.data.result.wickets;
                        }
                        this.is_data_ready = true;
                    })
            },
            main() {
                this.innings = this.$store.getters.getMatchDetailResponse.innings;

                if (this.innings[0] && this.innings[0].order) {
                    this.matchCurrentInning = this.activeInningIndex = this.innings.length - 1
                    return this.getWicket(this.innings[this.innings.length - 1])
                }else {
                    this.is_data_ready = true;
                }
            }
        },

    }
</script>
